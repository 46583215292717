import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ms-word-tokens"
    }}>{`MS Word Tokens`}</h1>
    <p>{`Dragnet can embed tokens in MS Word documents.  Our MS Word tokens are compliant `}<inlineCode parentName="p">{`DOCX`}</inlineCode>{` files and do NOT contain any macros.  When the file is opened in Microsoft Word, an HTTP request is sent to a remote server sounding the alarm.`}</p>
    <p>{`To create an MS Word token, click `}<inlineCode parentName="p">{`New Token`}</inlineCode>{` and select `}<inlineCode parentName="p">{`MS Word`}</inlineCode>{` from the top dropdown menu and complete the form as you would for any other token.`}</p>
    <p>{`To deploy the token simply `}<inlineCode parentName="p">{`Activate`}</inlineCode>{` and `}<inlineCode parentName="p">{`Download`}</inlineCode>{`from from the token's detail view and place the document where you want to add security that has MS Word installed.`}</p>
    <p>{`MS Word are extremely effective when placed on any machine with MS Word.  `}</p>
    <p>{`Note that when you add a Word document to a computer, the first time it is opened, Word may open the file in `}<inlineCode parentName="p">{`Protected View`}</inlineCode>{`. If it does, you will need to click `}<inlineCode parentName="p">{`Enable Editing`}</inlineCode>{` and save the document.  This will ensure that if it is opened by a threat actor, they will not see the prompt.`}</p>
    <p><img alt="ms-word" src={require("./public/MSWord-1.png")} /></p>
    <h3 {...{
      "id": "limitations"
    }}>{`Limitations`}</h3>
    <p>{`MS Word tokens do not work in web-based Word editors and previewers.`}</p>
    <h3 {...{
      "id": "templates"
    }}>{`Templates`}</h3>
    <p>{`We presently offer a single template for Word documents.  The document is called "accounts-and-passwords.docx" and it is filled with sensitive but fake data.  The token WILL continue to work if you modify the contents of the file or rename the file entirely. The trigger mechanism is embedded in the document header.  So we recommend not editing the header if you want to modify the template. The ability to upload your own custom Word templates is planned.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      